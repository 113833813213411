.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.display-box{
  height: 500px;
  width: 767px;
  border-radius: 10px;
  background-color: white;

}

.profile-circle {
  height: 200px;
  width: 200px;
  border: 1px solid #8C9EED;
  border-radius: 50%;
  cursor: pointer;
}
.post-profile-circle{
  height: 75px;
  width: 75px;
  border: 1px solid #8C9EED;
  border-radius: 50%;
  cursor: pointer;
}
.circle {
  height: 200px;
  width: 200px;
  background-color: #555;
  border-radius: 50%;
  cursor: pointer;
}
.circle:hover {
  background-color: #8C9EED;
}
.active {
  color: #8C9EED;
  cursor: pointer;
}
.active:hover {
  color: #8C9EED;
  cursor: pointer;
}
.auth-buttons {
  cursor: pointer;
}
.auth-buttons:hover {
  color: #8C9EED;
}

.btn-theme {
  background-color: #5271FF !important;;
  border-color: #5271FF !important;; 
}
.btn-theme:hover{
  background-color: #8C9EED !important;;
  border-color: #8C9EED !important;;
}
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}



.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.btn-auth {
  font: inherit;
  cursor: pointer;
  border-radius: 4px;
  background-color: #49ba3d;
  color: white;
  border: 1px solid #49ba3d;
 
}
.block-icon {
  position: relative;
  display: inline-flex;
}

.icon-tag {
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 6px;
  /* width: 12px !important;
  height: 12px !important; */
}

.blur-btn {
  cursor:pointer;
  border-color: #5271FF;
  background-color: white;
  color:#5271FF;
}
.blur-btn:hover {
  cursor:pointer;
  border-color: #5271FF;
  background-color: #5271FF;
  color:white;
}

.icon-tag:hover{
  color: black
}
.btn-auth:hover {
  background-color: #abe0a7;
  border-color: #abe0a7;
  color: white
}
.btn-post {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #5271FF;
  color: white;
  border: 1px solid #5271FF;
  margin: 0 1rem;
}
.btn-post:hover {
  background-color: #8C9EED;
  border-color: #8C9EED;
  color: white
}

.blur-image{
  filter: blur(8px);
 -webkit-filter: blur(8px);
}
.btn-trending {
  background-color: #ddd;
  padding: 3px 8px;
  margin: 4px 2px;
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 16px;
}
.btn-trending:hover {
  background-color: #8C9EED;
  border-color: #8C9EED;
  color: white
}
.btn-tags {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}
.btn-tags:hover {
  background-color: #8C9EED;
  border-color: #8C9EED;
  color: white
}
.btn-tags-active {
  background-color: #8C9EED;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}
.btn-tags-active:hover {
  background-color: #ddd;
  border-color: #8C9EED;
  color: black
}
.btn-subscribe {
  font: inherit;
  padding: 0.25rem .75rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #5271FF;
  color: white;
  border: 1px solid #5271FF;
  margin: 0 1rem;
}
.btn-subscribe:hover {
  background-color: #8C9EED;
  border-color: #8C9EED;
  color: white
}

.btn-learn {
  background-color: white;
  border-color: #5271FF;
  color: #5271FF;
  border-radius: 25px;
}
.btn-learn:hover {
  background-color: #5271FF;
  border-color: #5271FF;
  color: white;
  border-radius: 25px;
}

.btn-learn-click {
  background-color: #5271FF;
  border-color: #5271FF;
  color: white;
  border-radius: 25px;
  
}
.btn-learn-click:hover {
  background-color: white;
  border-color: #5271FF;
  color: #5271FF;
  border-radius: 25px;
}

.profile-pic {
  display: inline;
  margin: 0 auto;
  
  height: 55px;
  width: 55px;
  border-radius: 100%;
}
.post-profile-pic {
  display: inline;
  margin: 0 auto;
  
  height: 30px;
  width: 30px;
  border-radius: 100%;
}

.card-feed{
  cursor: pointer;
  padding-top: 25px;
  padding-bottom: 25px;
  /* transition: transform 450ms */
}
.card-feed:hover{
  /* transform: scale(1.02); */
  background-color: #f8f9fa;
}
.profile-pic-detail {
  display: inline;
  margin: 0 auto;
  
  height: 75px;
  width: 75px;
  border-radius: 100%;
}


.type{
  color: gray;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.type-select{
  color: gray;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: 4px solid #8C9EED;
}


.profile-pic-circle {
  display: inline;
  margin: 0 auto;
  
  height: 125px;
  width: 125px;
  border-radius: 100%;
}
.comment-pic {
  display: inline;
  margin: 0 auto;
  
  height: 70px;
  width: 70px;
  border-radius: 100%;
}
.profile-pic-explorer{
  display: inline;
  margin: 0 auto;
  height: 100px;
  width: 100px;
  border-radius: 100%;
}
.post-card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.post-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.profile-bar-icons {
  color: gray;
}
.profile-bar-icons:hover {
  color: #8C9EED
}
/* Add some padding inside the card container */

.Subbar #active {
  color: #8C9EED;

}
.like-hover:hover{
  color: red
}



.search-bar {
  color: white;
  border-color:white;
  background-color: #8C9EED
}
.search-bar:hover {
  color:#8C9EED;
  border-color:#8C9EED;
  background-color: white
}

.find-bar {
  font: inherit;
  padding: 0.0rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #5271FF;
  color: white;
  border: 1px solid #5271FF;
  margin: 0 1rem;
}
.find-bar:hover {
  background-color: #8C9EED;
  border-color: #8C9EED;
  color: white
}
.decline-bar {
  font: inherit;
  padding: 0.0rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #df3921;
  color: white;
  border: 1px solid #df3921;
  margin: 0 1rem;
}
.decline-bar:hover {
  background-color: #f1553d;
  border-color: #f1553d;
  color: white
}

.group-bar {
  font: inherit;
  padding: 0.0rem 1.5rem;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;
  color: #5271FF;
  border: 1px solid #5271FF;
  margin: 0 1rem;
}
.group-bar:hover {
  background-color: #5271FF;
  border-color: #5271FF;
  color: white
}

.custom-dialog {width:100% !important; top:-50%;}

.close-button{
  transform: scale(2);
  cursor: pointer
}
.close-button:hover{
  color:#8C9EED;
}

.row_poster {
  width: 100%;
  object-fit:contain;
  max-height: 125px;
  
  cursor:pointer;
  transition: transform 450ms
  
}
.row_posters{
  display:flex;
  overflow-y: hidden;
  overflow-x:scroll;
  padding-bottom: 300px;
  padding-top:20px;
  transition: transform 450ms
}
.row_posters::-webkit-scrollbar{
  display:none;
}

.row_poster:hover{
  transform: scale(1.05);
 
}

.main-feed{

  transition: transform 450ms
}
.main-feed:hover{
  transform: scale(1.05);
}
.widthmax {
  max-width: '100%'  
}

.explorer_box{

    height: 500px;
    overflow-y: scroll;
  
}
.profile-box{
  height: 200px;
  
  width: 200px;
  cursor: pointer;
  transition: transform 450ms
}

.profile-box:hover{
  transform: scale(1.05);
}
.image_modal{
  height: 200px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: transparent;
}

.overlay:hover {
  opacity: .5;
}
.on-hover:hover{
  background-color: #f8f9fa;
}
.img-mod {
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
}
#inputID::placeholder {
  color: #FBEAEB;
  opacity: 1;
}
#input-file-upload {
  display: none;
}
#input-file-uploads {
  display: none;
}
a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}
